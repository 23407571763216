const sortByProperty = (property: string, objA: any, objB: any) => {
  const a = objA[property];
  const b = objB[property];

  const propertyA = typeof a === 'string' ? a.toUpperCase() : a;
  const propertyB = typeof b === 'string' ? b.toUpperCase() : b;

  if (propertyA < propertyB) {
    return -1;
  }

  if (propertyA > propertyB) {
    return 1;
  }

  return 0;
};

const pick = (obj: any, keys: any[]) =>
  Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key]])
  );

const chunkArray = <T>(array: T[], perChunk = 50): T[][] =>
  array.reduce((resultArray: T[][], item, index) => {
    const acc = resultArray;

    const chunkIndex = Math.floor(index / perChunk);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }

    acc[chunkIndex].push(item);

    return acc;
  }, []);

const arrayIncludesAnyElementInOtherArray = <T>(
  arrayHaystack: T[],
  arrayNeedle: T[]
) =>
  arrayHaystack.some((arrayHaystackElement) =>
    arrayNeedle.includes(arrayHaystackElement)
  );

export {
  sortByProperty,
  pick,
  chunkArray,
  arrayIncludesAnyElementInOtherArray,
};
