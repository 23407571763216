<template>
  <div class="flex h-full flex-col">
    <div
      class="relative z-10 flex h-16 min-h-16 w-full items-center justify-between"
      :class="displayShadow ? 'shadow-drop-sm' : 'border-b'"
    >
      <slot name="header" />
    </div>
    <div
      ref="sidebarContent"
      v-scroll-top="(isScrollTop: boolean) => (displayShadow = !isScrollTop)"
      class="h-full overflow-auto overscroll-contain"
    >
      <slot />
    </div>
    <div
      v-if="$slots['footer']"
      class="z-10 mt-auto flex flex-auto flex-col rounded-t-xl bg-white px-6 pb-12 pt-4 shadow-drop-lg"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
const displayShadow = ref(false);
</script>
