import { defineNuxtPlugin, useNuxtApp } from '#app';
import { createApp, h, ref } from 'vue';

declare module '#app' {
  interface NuxtApp {
    $notification: {
      error(message: string): void;
    };
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const { ssrContext } = useNuxtApp();
  if (!ssrContext) {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const baseNotificationStyles =
      'fixed top-9 right-4 md:right-8 text-white p-3 rounded z-50 ease-out duration-300 bg-background-red';
    const hiddenNotificationStyles = `${baseNotificationStyles} transform translate-x-96`;
    const visibleNotificationStyles = `${baseNotificationStyles} transform translate-x-0`;

    const notificationMessage = ref('');
    const notificationClass = ref(hiddenNotificationStyles);
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const showNotification = (message: string) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      notificationMessage.value = message;
      notificationClass.value = visibleNotificationStyles;

      timeoutId = setTimeout(() => {
        notificationClass.value = hiddenNotificationStyles;
      }, 4000);
    };

    const NotificationComponent = {
      setup() {
        return () =>
          h(
            'div',
            {
              class: notificationClass.value,
            },
            notificationMessage.value
          );
      },
    };

    const notificationApp = createApp(NotificationComponent);
    notificationApp.mount(container);

    nuxtApp.provide('notification', {
      error: showNotification,
    });
  }
});
