<template>
  <NuxtLink
    class="flex items-center"
    :to="localePath({ path: '/' })"
    :aria-label="$t('aria.back_to_start_page')"
  >
    <BrandLogo
      class="flex size-full items-center text-primary-default [&>svg]:max-w-[250px]"
    /><span class="hidden">Menu</span>
  </NuxtLink>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
</script>
